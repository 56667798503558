class MessageParser {
    constructor(actionProvider, state) {
        this.actionProvider = actionProvider;
        this.state = state;
    }

    parse(message) {
        if (message !== "") {
            const tolowercaseMsg = message.toLowerCase();
            if (tolowercaseMsg.includes('hi') || tolowercaseMsg.includes('hello') || tolowercaseMsg.includes('how are you')) {
                this.actionProvider.welcomeMsg();
            } else if (tolowercaseMsg.includes('onlogis') || tolowercaseMsg.includes('erp')) {
                this.actionProvider.handleWhatIsOnlogis();
            } else if (tolowercaseMsg.includes('modules')) {
                this.actionProvider.modules();
            } else if (tolowercaseMsg.includes('options') || tolowercaseMsg.includes('purchasing')) {
                this.actionProvider.optBuy();
            } else if (tolowercaseMsg.includes('help center') || tolowercaseMsg.includes('contact')
                || tolowercaseMsg.includes('phone number') || tolowercaseMsg.includes('email')) {
                this.actionProvider.contactInfo();
            } else if (tolowercaseMsg.includes('pricing') || tolowercaseMsg.includes('plans')) {
                this.actionProvider.pricing();
            } else if (tolowercaseMsg.includes('thanks') || tolowercaseMsg.includes('thank you')
                || tolowercaseMsg.includes('ok')) {
                this.actionProvider.msgThanks();
            } else {
                this.actionProvider.myAlert();
            }
        }
    }
}

export default MessageParser;

// if (message !== "") {
//     const tolowercaseMsg = message.toLowerCase();
//     if (tolowercaseMsg.includes('onlogis')) {
//         this.actionProvider.handleWhatIsOnlogis();
//     } else if (tolowercaseMsg.includes('modules')) {
//         this.actionProvider.modules();
//     } else if (tolowercaseMsg.includes('options') || tolowercaseMsg.includes('purchasing')) {
//         this.actionProvider.optBuy();
//     } else if (tolowercaseMsg.includes('help center') || tolowercaseMsg.includes('contact') || tolowercaseMsg.includes('phone number')) {
//         this.actionProvider.contactInfo();
//     } else if (tolowercaseMsg.includes('difference') || tolowercaseMsg.includes('differentiate')) {
//         this.actionProvider.different();
//     } else if (tolowercaseMsg.includes('pricing') || tolowercaseMsg.includes('plans')) {
//         this.actionProvider.pricing();
//     }
// } else {
//     this.actionProvider.myAlert();
// }