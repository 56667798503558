import React from 'react';
import '../../App.css';
import '../css/Discover.css';
import { Helmet } from 'react-helmet';

const Features = () => {
    return (
        <>
            <Helmet>
                <title>Onlogis - Features</title>
                <meta name="description" content="Our Software features includes ,Suppliers, Customers, Transportation, warehouse management, Commands approval and Customs clearince management..." />
            </Helmet>
            <div className="top mb-sm-5 pt-5" style={{ minHeight: '400px', background: '#f7f7f7' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex justify-content-between align-items-center">
                        <div data-aos="fade-down">
                            {/* <h2 className="fs-2 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }} >ERP Software For Logistics <br /> Management</h2> */}
                            <p className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" style={{ color: '#797979' }}>
                                Onlogis Cloud ERP software for transportation and logistics management will serve you as an effective tool and strong support in driving your business to success
                            </p>
                        </div>
                        <img srcSet="/en/img/img1.png" className="img-fluid mt-4 mt-sm-0" data-aos="zoom-in-up" alt="Logistics and transportation management" />
                    </div>
                </div>
            </div>
            {/* Onlogis Features */}
            <div className="container my-container" style={{ overflow: 'hidden' }}>
                <div className="row mt-5">
                    <div className="col-md-6 me-auto mt-5 p-sm-0 full-w">
                        {/* <div className="mb-5" data-aos="fade-up">
                            <h1 className="fw-normal fs-3 text-sm-start text-center mb-2 lh-base" style={{ color: '#797979' }}>Let's Features</h1>
                        </div> */}
                        <div className="row mb-3 ">
                            <div className="col-md-2 text-center" data-aos="zoom-in-down">
                                <div className="circle text-center mx-auto">
                                    <img srcSet="/en/img/mission1.png" className="img-fluid" alt="mission" />
                                </div>
                            </div>
                            <div className="col-md-10" data-aos="fade-down">
                                <h4 className="fs-4 fw-normal lh-sm text-sm-start text-center" style={{ color: '#ED7117' }}>Our mission</h4>
                                <p className="lh-base fw-normal fs-6 text-sm-start text-center">
                                    To simplify the management of Freight forwarders and logistic companies on all levels with an all-in-one integrated cloud based specialized software designed according to their needs. <br /> We digitalize the identity of the company into information system providing decision makers with real time data to gain a
                                    complete control and visibility over the company
                                </p>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-2 text-center" data-aos="zoom-in-down">
                                <div className="circle text-center mx-auto">
                                    <img srcSet="/en/img/vidion1.png" className="img-fluid" alt="vision" />
                                </div>
                            </div>
                            <div className="col-md-10" data-aos="fade-up">
                                <h4 className="fs-4 fw-normal lh-sm text-sm-start text-center" style={{ color: '#ED7117' }}>Our Vision</h4>
                                <p className="lh-base fw-normal fs-6 text-sm-start text-center">
                                    We thrive to provide small and medium companies the opportunity to have information system tools to compete efficiently with large logistics enterprises
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 my-5 p-sm-0 full-w">
                        <img srcSet="/en/img/illustration1.png" data-aos="zoom-in-up" className="img-fluid w-sm-100" alt="Logistics management software" />
                    </div>
                </div>
            </div>
            <div className="features p-sm-5 pt-3 text-center" style={{ background: "#F7F7F7", minHeight: '900px' }}>
                <div className="container my-container">
                    <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>System <span style={{ color: '#ED7117' }}>Features</span></h1>
                    <div className="line"></div>
                    <div className="row mt-5">
                        <div className="feature col-md-3 me-sm-auto me-0 mb-3 px-2 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/icn1.png" alt="approval Management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Commands approval Management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        Onlogis offers you in a single module, a set of tools necessary for a complete management of your freight forwarding operations approval. This module allows you to ease your
                                        daily approvals and improve your processes.
                                        We offer you a Built-in approvals system to control your operations and invoices in an easy and secure way, allowing the perfect management of your resources
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/user.png" alt="Suppliers and Customers" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Suppliers and Customers Management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        To manage in a better way the relationship between your company and your suppliers and customers, Onlogis gives you a clear visibility into your operations
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/13.png" alt="Transportation and warehouse management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Transportation and warehouse management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        With Onlogis transportation and warehouse management functionality, you can accurately forecast demand and shipment volumes, enhance freight management, consolidate orders, maximize the return on your transportation spend, and gain real-time visibility into domestic and international shipping across all transportation modes,
                                        also, it provides a warehouse management
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/14.png" alt="Customs Clearance Management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Customs Clearance Management </h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        With Onlogis there is accurate reports, documentation, compliance easiness and real-time regulations updates.
                                        The customs clearince feature make's it easy to avoid human error probability that reduces the chances of penalty or any kind of fine.; it provides a complete inventory management solution making reporting,
                                        recording, and evaluation much efficient than usual
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/1.png" alt="Reporting and Business analysis" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Reporting and Business analysis</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        To drive your business to success, you need effective business indicators. Onlogis offers well-calculated dashboards dynamically in real time, based on your configured performance indicators
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/user.png" alt="User permission" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Administration permissions</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        Our ERP integrates a permission management tool that allow to define the rights of users through automatic rules or through a manual assignment of rights; benefit from traceability of changes and transactions by your users,
                                        thanks to a simple and readable interface
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/12.png" alt="Financial Management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Financial Management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        Onlogis offers a powerful financial accounting and statistical tool on your activities to remain powerful and active in the competitive market: This module offers full functionality for a complete accounting management with a
                                        flexible and easy interface for the user
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 me-sm-auto px-2 mb-3 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/user.png" alt="HR Management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">HR Management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        Onlogis module streamlines the management of human resources and human capital. With Onlogis all HR submodules are integrated in one. It aims to routinely maintain a complete employee database including contact information, salary details, attendance, performance evaluation, and promotion of all employees
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="feature col-md-3 px-2 full-w">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                                <div className="circle-2">
                                    <img srcSet="/en/img/13.png" alt="Customer Relationship Management" />
                                </div>
                                <div className="mt-3">
                                    <h2 className="fs-5 fw-normal lh-base">Customer Relationship Management</h2>
                                    <p className="fs-6 fw-normal lh-base" style={{ color: "#797979" }}>
                                        With Onlogis CRM Module,  we guarantee you to boost your costumers relationship, increase their satisfaction thus provide profitability to your business and maintain a leading position in the market.  Our CRM module allow you different functionalities mainly a centralized management of all your contacts, customers,
                                        suppliers, and partnership prospects
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="values p-sm-5 text-center w-100 mt-5 mt-sm-0" style={{ background: "#fff", minHeight: '300px' }}>
                <div className="container my-container">
                    <h3 className="fw-bold fs-3 text-center  mb-sm-2 lh-base" style={{ color: '#797979' }}>We add value to <span style={{ color: '#ED7117' }}>your business</span></h3>
                    <div className="line mb-3"></div>
                    <img srcSet="/en/img/erp.png" className="img-fluid" data-aos="zoom-in-up" alt="onlogis erp" />
                </div>
            </div>
            <div className="faq p-sm-5 pt-3  w-100" style={{ background: "#F7F7F7", minHeight: '400px' }}>
                <h3 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Frequently asked <span style={{ color: '#ED7117' }}>questions</span></h3>
                <div className="line"></div>
                <div className="container my-container mt-5">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    As a small company with limited experience in logistics will I be accompanied in the different phases of this ERP commitment?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes, we ensure to accompany you all along the phase of setting up the system; installation, customization, and training through our team of experts guided by commercials; technical assistance teams; and local experts partners</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    How long will it take to implement Onlogis ERP?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">It depends on your company’s unique organizational requirements. Onlogis offer all its potential clients a free consultation session with its team of experts. During the session, we will be able to approximate the project duration based on the needs,  modules customization, and the implementation training processes </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    How Onlogis will be able to increase my ROI?
                                </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Onlogis measures the potential return on investment (ROI) in terms of cost and time savings achieved when automating manual work ,digitalizing the company identity, and gaining visibility and control over the different departments and processes. With Onlogis distinguished features there is a value of reduced risks and improvement corporate governance</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Will I be charged for every user ?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes, however, Onlogis has a very affordable pricing strategy that fit every client budget and requirements in contradictory of the well known logistics management system. For unlimitted users, entreprise plan,
                                    You will be charged with one initial payment from 5000$ to 30000$ depending on your company size and customized modules
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    Can I use Onlogis functionalities for free ?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Yes, with a time limit. Onlogis offers your company this free trial with no money commitement
                                    guarantee
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Features;