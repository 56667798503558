import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './components/partials/NewNavbar';
import Home from './components/pages/Home';
import FooterApp from './components/partials/Footer';
import Features from './components/pages/Features';
import Pricing from './components/pages/NewPricing';
import Installation from './components/pages/Installation';
import TechnicalSupport from './components/pages/TechnicalSupport';
import Privacy from './components/pages/Privacy';
import VideoTraining from './components/pages/VideoTraining';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTop';
import SignUp from './components/pages/auth/SignUp';
import SignIn from './components/pages/auth/SignIn';
// import Page404 from './components/pages/Page404';
import Contact from './components/pages/Contact';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './components/chatbot/config';
import ActionProvider from './components/chatbot/ActionProvider';
import MessageParser from './components/chatbot/MessageParser';
import Demo from './components/pages/Demo';

export const API_URL = "http://localhost:5000/api";

function App() {
  const [showChatbot, setShowChatbot] = useState(false);
  
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  
  return (
    <>
      <Router>
        <a href="https://wa.me/+442037692827" className="link whatsapp_float" target="_blank" rel="noreferrer"> <i className="fab fa-whatsapp whatsapp-icon"></i></a>
        <button className='btn-chatbot' onClick={() => setShowChatbot(!showChatbot)}>
          {
            !showChatbot ? <i className="fab fa-facebook-messenger"></i> : <i className="fas fa-times"></i>
          }
        </button>
        {
          showChatbot && <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        }
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/en/" element={<Home />} />
          <Route path="/en/features" element={<Features />} />
          <Route path="/en/pricing" element={<Pricing />} />
          <Route path="/en/user-guide" element={<Installation />} />
          <Route path="/en/technical-support" element={<TechnicalSupport />} />
          <Route path="/en/privacy" element={<Privacy />} />
          <Route path="/en/demo-training" element={<VideoTraining />} />
          <Route path="/en/sign-up" element={<SignUp />} />
          <Route path="/en/sign-in" element={<SignIn />} />
          <Route path="/en/request-a-quote" element={<Contact />} />
          <Route path="/en/request-a-demo" element={<Demo />} />
          {/* <Route path="*" element={<Page404 />} /> */}
        </Routes>
        <FooterApp />
      </Router>
    </>
  );
}

export default App;

