import React from 'react';
import '../../App.css';
import { Helmet } from 'react-helmet';
import { Button } from '../partials/Button';
import { Link } from 'react-router-dom';

function VideoTraning() {
    return (
        <>
            <Helmet>
                <title>Onlogis - Demo Training</title>
                <meta name="description" content="Preview the video demo of each feature to help guide your understanding  of the logistics and transportation management software" />
            </Helmet>
            <div className="top mb-5" style={{ minHeight: '400px', background: '#f7f7f7' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex flex-sm-row justify-content-between align-items-center">
                        <p data-aos="fade-down" className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" style={{ color: '#797979' }}>
                            To support onlogis customers in the process of understanding and using the logistics management software, a set of demo videos are on display. <br />
                            Preview the demo of each feature to help guide your understanding
                        </p>
                        <img srcSet="/en/img/video1.png" className="/en/img-fluid mt-4 w-50 img-mobile" data-aos="zoom-in-up" alt="Demo Training" />
                    </div>
                </div>
            </div>
            <div className="container my-container my-5">
                <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Demo <span style={{ color: '#ED7117' }}>Training</span></h1>
                <div className="line"></div>
                <div className="row text-center mb-5 mt-5">
                    {/* <div className="col-md-6">
                        <img srcSet="/en/img/test-v.png" className="img-fluid" alt="" />
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="fs-5 fw-bold lh-base mt-2">INTRODUCTION OVERVIEW</h2>
                            <span className="fs-6 lh-base">by Onlogis</span>
                        </div>
                    </div> */}
                    <div>
                    
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/5zWW7uwpfSM"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <h2 className="fs-5 fw-bold lh-base mt-2">INTRODUCTION OVERVIEW</h2>
                    </div>
                    {/* <div className="col-md-6">
                        <img srcSet="/en/img/test-v.png" className="img-fluid" alt="" />
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="fs-5 fw-bold lh-base mt-2">ADMINISTRATION</h2>
                            <span className="fs-6 lh-base">by Onlogis</span>
                        </div>
                    </div> */}
                </div>
                {/* <div className="row mt-5 text-center">
                    <div className="col-md-4 width-50">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">BILLING</h2>
                    </div>
                    <div className="col-md-4 width-50">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">DASHBORD</h2>
                    </div>
                    <div className="col-md-4">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid  full-w" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">Commercial-add customer</h2>
                    </div>
                </div> */}
                {/* <div className="row text-center">
                    <div className="col-md-4 width-50">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">Commercial-customer list</h2>
                    </div>
                    <div className="col-md-4 width-50">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">Commercial-Management Offers</h2>
                    </div>
                    <div className="col-md-4">
                        <img srcSet="/en/img/test-v-mini.png" className="img-fluid full-w" alt="" />
                        <h2 className="fs-6 fw-bold lh-base w-100 mt-2">Commercial-Fixed price manage</h2>
                    </div>
                </div> */}
                <div className="row mt-3 text-center">
                    <div className="col-md-3 mx-auto center width-50">
                        <Button>
                            <Link className='link' to="/en/pricing">Choose Your Plan</Link>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoTraning;
