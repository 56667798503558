export const MenuItems = [
    {
        ItemName: 'Home',
        url: '/en',
        cName: 'nav-link'
    },
    {
        ItemName: 'Features',
        url: '/en/features',
        cName: 'nav-link'
    },
    {
        ItemName: 'Pricing Plans',
        url: '/en/pricing',
        cName: 'nav-link'
    },
    {
        ItemName: 'Privacy & Policy',
        url: '/en/privacy',
        cName: 'nav-link'
    }
]