import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../partials/Button';
import '../css/Features.css';
import Testimonials from '../partials/NewTestimonial';
import Clients from '../partials/Clients';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <>
      <Helmet>
        <title>Onlogis - Transportation and Logistics Software </title>
        <meta name="description" content="Onlogis - The Cloud ERP all in one integrated  Solution for Transportation and 
        Logistics Management destinated to Logistics & Freight Forwarding Companies" />
      </Helmet>
      <div className="hero p-1 p-sm-5 text-center text-sm-start" style={{ background: '#f7f7f7', minHeight: '600px' }}>
        <div className="container my-container">
          <div className="my-flex-column d-sm-flex align-items-center justify-content-between">
            <div className="intro text-center text-sm-start" data-aos="fade-down">
              <h1 className="text-capitaize fw-normal mb-4 mb-sm-0 pt-2 pt-sm-0 lh-base" style={{fontSize : '2.2rem'}}>
                Transportation & Logistics Management <span style={{ color: '#ED7117' }}>Software</span>
              </h1>
              <p className="fs-5 lead my-sm-4 fw-normal lh-base mb-4" style={{ color: '#797979' }}>
                The revolutionary CLOUD ERP all in one integrated based Solution for Logistics and Freight Forwarding Companies
              </p>
              <Link to="/en/pricing">
                <Button buttonSize="btn-lg">
                  Get a plan
                </Button>
              </Link>
            </div>
            <img srcSet="/en/img/banner.svg" className="img-fluid mt-4 mt-sm-0" data-aos="zoom-in-up" alt="Transportation & Logistics Management Software" />
          </div>
        </div>
      </div>
      <div className="why-onlogis p-0 p-sm-5 p-lg-0 text-center text-sm-start mt-5 w-100" style={{ background: '#fff', minHeight: '800px' }}>
        <div className="container my-container">
          <div className="row text-center">
            <h2 className="fs-2 fw-bold lh-base" style={{ color: '#797979' }}>Why <span style={{ color: '#ED7117' }}>Onlogis</span></h2>
            <div className="line mb-4"></div>
          </div>
          <div className="my-flex-column d-sm-flex align-items-center justify-content-between">
            <div data-aos="fade-up" className="text-start ">
              <h4 className="mb-4 fs-5 lh-base" style={{ color: '#ED7117' }}>
                Visibility and control over your operations anywhere and anytime...
              </h4>
              <div className='fs-6 text-capitalize'>
                <i className="fas fa-check mb-3" style={{ color: '#ED7117' }}></i> Dedicated solution for the Industry <br />
                <i className="fas fa-check mb-3" style={{ color: '#ED7117' }}></i> Logistics & Transportation Management <br />
                <i className="fas fa-check mb-3" style={{ color: '#ED7117' }}></i> Personalized Modules/Functionalities according to need <br />
                <i className="fas fa-check mb-3" style={{ color: '#ED7117' }}></i> real-time visibility and control for all your operations <br />
                <i className="fas fa-check" style={{ color: '#ED7117' }}></i> Improvement of company's workflow
              </div>
            </div>
            <img srcSet="/en/img/dashboard.png" className="img-fluid" data-aos="zoom-in-up" alt="Onlogis Dashboard" />
          </div>
        </div>
      </div>
      <div className="features p-sm-5 p-lg-0 w-100" style={{ background: '#fff', minHeight: '800px' }}>
        <div className="container my-container">
          <div className="row text-center">
            <h2 className="fs-2 fw-bold lh-base" style={{ color: '#797979' }}>Onlogis Distinguishing <span style={{ color: '#ED7117' }}>Features</span></h2>
            <div className="line"></div>
          </div>
          <div className="row align-items-center justify-content-between mt-5">
            <div className="col-md-4 full-w p-0">
              <img srcSet="/en/img/11.png" className="img-fluid" data-aos="zoom-in-up" alt="Onlogis distinguishing features" />
            </div>
            <div className="col-md-8 full-w pt-5">
              <div className="row">
                <div className="col-md-4 text-center col-sm-12 col-xs-12 mb-3">
                  <div className="circle m-auto" data-aos="zoom-in-down">
                    <img srcSet="/en/img/319.png" alt="Distinguishing Features" />
                  </div>
                </div>
                <div className="col-md-8 text-md-start text-center col-sm-12 mb-3" data-aos="zoom-in-up">
                  <h3 className="fs-4 lh-sm fw-normal text-capitalize" style={{ color: '#ED7117' }}>Specific features dedicated for logistics companies</h3>
                  <p className="fs-5 fw-normal">
                    The flexible software combines specific dedicated features that respond to any logistics and freight forwarding
                    company needs in one database.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center col-sm-12 col-xs-12 mb-3">
                  <div className="circle m-auto" data-aos="zoom-in-down">
                    <img srcSet="/en/img/319.png" className="img-fluid" alt="Distinguishing Features" />
                  </div>
                </div>
                <div className="col-md-8 text-md-start text-center col-sm-12 mb-3" data-aos="zoom-in-up">
                  <h3 className="fs-4 lh-sm fw-normal text-capitalize" style={{ color: '#ED7117' }}>Reporting tools destinated for decision markers</h3>
                  <p className="fs-5 fw-normal">
                    Onlogis provides a robust analysis and reporting tool for decision makers and business users. <br /> This tool simplifies key comprehensive reporting data and enable managers with intuitive feedback
                  </p>
                </div>
              </div>
              
              <div className="row">
                <div className="col-md-4 text-center col-sm-12 col-xs-12 mb-3">
                  <div className="circle m-auto" data-aos="zoom-in-down">
                    <img srcSet="/en/img/319.png" alt="Distinguishing Features" />
                  </div>
                </div>
                <div className="col-md-8 text-md-start text-center col-sm-12 mb-3" data-aos="zoom-in-up">
                  <h3 className="fs-4 lh-sm fw-normal text-capitalize" style={{ color: '#ED7117' }}>Fully integrated departments in one centralize Database </h3>
                  <p className="fs-5 fw-normal">
                    Onlogis incorporates  several modules including financial, HR , Operations, transportation & warehouse management, CRM into one centralize database for a  complete digital transformation of the company different operations
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center col-sm-12 col-xs-12 mb-3">
                  <div className="circle m-auto" data-aos="zoom-in-down">
                    <img srcSet="/en/img/319.png" className="img-fluid" alt="Distinguishing Features" />
                  </div>
                </div>
                <div className="col-md-8 text-md-start text-center col-sm-12 mb-3" data-aos="zoom-in-up">
                  <h3 className="fs-4 lh-sm fw-normal text-capitalize" style={{ color: '#ED7117' }}>Real time dashboard</h3>
                  <p className="fs-5 fw-normal">
                    With Onlogis decision makers have a  real time dashboard visualization
                    incorporating key indicators for transportation and logistics management thus getting complete
                    control over day-to-day operations
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="us p-0 p-sm-5 p-lg-5 text-center mt-5 w-100" style={{ background: '#fff', minHeight: '500px' }}>
        <div className="container my-container">
          <div className="row">
            <h2 className="fw-bold fs-1 text-center mb-2 lh-base" style={{ color: '#797979' }}>What <span style={{ color: '#ED7117' }}>Differentiate Us</span></h2>
            <div className="line mb-5"></div>
            <div className="col-md-6 p-3">
              <div className="d-sm-flex flex-sm-column  align-items-center justify-content-between" data-aos="zoom-in-up">
                <img srcSet="/en/img/1.png" className="mb-sm-3 mb-3" alt="Transformation" />
                <div className="ms-sm-4">
                  <h4 className="fs-5 lh-sm fw-bolder text-capitalize  text-center">Digital transformation</h4>
                  <p className="fs-6 fw-normal text-sm-start text-center">
                    We offer a solution to digitalize your company with customized ERP TOOLS. <br /> With the best practices we accompany  you through cultural change of the company taking into consideration local factors and constraints.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="d-sm-flex flex-sm-column  align-items-center justify-content-between" data-aos="zoom-in-up">
                <img srcSet="/en/img/2.png" className="mb-sm-3 mb-3" alt="Supply Chain And Logistics knowledge" />
                <div className="ms-sm-4" >
                  <h4 className="fs-5 lh-sm fw-bolder text-capitalize text-center">Deep know how in Supply Chain and Logistics</h4>
                  <p className="fs-6 fw-normal text-sm-start text-center">
                    Years of extensive work in the field has guaranteed us a very good established knowledge in logistics operations process and supply chain management. <br /> Over the years, our experts have gathered the knowledge to provide intelligent solutions to respond to the operation obstacles of our clients.
                    {/* We are a part of a strong  IML partners network. */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="d-sm-flex flex-sm-column  align-items-center justify-content-between" data-aos="zoom-in-up">
                <img srcSet="/en/img/3.png" className="mb-sm-3 mb-3" alt="Entreprise customization " />
                <div className="ms-sm-4">
                  <h4 className="fs-5 lh-sm fw-bolder text-capitalize text-center">Customization for medium and small enterprises</h4>
                  <p className="fs-6 fw-normal text-sm-start text-center" style={{ marginTop: '10px', textAlign: 'justify' }}>
                    We provide customization according to your budget, Onlogis is customizable depending on the specifications of your business processes. At the end of your modules configuration and your key performance indicators, your business management becomes very simple. <br /> All information are integrated, calculated and analyzed in relation with your business characteristics resulting in an efficient and productive tool
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="d-sm-flex flex-sm-column  align-items-center justify-content-between" data-aos="zoom-in-up">
                <img srcSet="/en/img/4.png" className="mb-sm-3 mb-3" alt="Continuous updates" />
                <div className="ms-sm-4 ">
                  <h4 className="fs-5 lh-sm fw-bolder text-capitalize text-center ">Flexibility and continuous feature updates </h4>
                  <p className="fs-6 fw-normal text-sm-start text-center">
                    Onlogis offers the flexibility to adapt to your internal change, market change, legislations, and different external factors.<br />We ensure to maintain a continuous updated ERP’s functionalities to «perfect operation» with a minimum cost, following the up-to-date development languages along with latest market trends as a part of our mission to accompany our clients into the digitization of all company’s operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonials />
      <Clients />
    </>
  )
}

export default Home;
