import React from 'react';
import '../../App.css';
import { Helmet } from 'react-helmet';

function Installation() {
    return (
        <>
            <Helmet>
                <title>Onlogis - User Guide </title>
                <meta name="description" content="Get the user guide for your business and learn to grow it. Onlogis dedicated experts are committed to accompany you throught every step in the process" />
            </Helmet>
            <div className="top mb-5 pt-3" style={{ minHeight: '400px', background: '#f7f7f7' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex flex-sm-row justify-content-between align-items-center">
                        <p data-aos="fade-down" className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" style={{ color: '#797979' }}>
                            Our commercials and technical assistance partners will guide your company's users throught
                            the process including all phases of setting up the software's features to your specific needs
                        </p>
                        <img srcSet="/en/img/installation1.png" className="img-fluid w-50 img-mobile" data-aos="zoom-in-up" alt="Cloud Storage Service" />
                    </div>
                </div>
            </div>
            <div className="my-5" style={{ background: '#fff', minHeight: '900px', width: '100%' }}>
                <div className="container my-container">
                    <div className="row">
                        <div className="col-md-6 full-w">
                            <img srcSet="/en/img/ins-guid.png" className="img-fluid w-100" data-aos="zoom-in-up" alt="Erp's different features" />
                        </div>
                        <div className="full-w col-md-6 text-sm-start text-center p-2 p-sm-5" data-aos="zoom-in-down" style={{ background: 'url("/en/img/Untitled.png")' }}>
                            <h3 className="fs-4 fw-normal lh-base my-5 text-center" style={{ color: '#ED7117' }}>Cloud Storage Services </h3>
                            <div className="mt-5" style={{ color: '#797979' }}>
                                <p className="fs-6 lh-base">
                                    Affordable and powerful ... We offer a range of dedicated servers, high performance for business projects, with a storage adapted according to your usage. Your servers will be hosted in our data center and maintained by our highly qualified technical team. To ensure that you will never be short of space on your server, we offer you the opportunity to expand your storage space by using our storage device on the cloud. This is in addition to your local RAID,
                                    default mirrors hard drives offered by default upon installation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="full-w col-md-6 text-sm-start text-center p-2 p-sm-5" data-aos="zoom-in-down" style={{ background: 'url("/en/img/Untitled.png")' }}>
                            <h3 className="fs-4 fw-normal lh-base my-5 text-center" style={{ color: '#ED7117' }}>WE'VE GOT WHAT YOU NEED!</h3>
                            <div className="mt-5 " style={{ color: '#797979' }}>
                                <p className="fs-6 lh-base">
                                    We ensure to accompany you all along the phase of setting up the system through our commercials and technical assistance partners whose presence is necessary. We will be the support system that drive your business to success.
                                    Our team of experts and partners in our local network are at your side and help you in every step of the installation, the customization, and the training <br />
                                    Our partners are present in Europe, Africa and South America at your disposal
                                    The solution is available in three languages: French, English and Spanish
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 full-w">
                            <img srcSet="/en/img/erp1.png" className="img-fluid w-100" data-aos="zoom-in-up" alt="entreprise resource planning" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Installation;
