import React from 'react';
import '../../App.css';
import { Helmet } from 'react-helmet';

function Privacy() {
    return (
        <>
            <Helmet>
                <title>Onlogis - Privacy & Policy</title>
                <meta name="description" content="Facing a technical installation problem? connect to our technical support team and sort out every obstacle" />
            </Helmet>
            <div className="top mb-5 pt-3" style={{ minHeight: '100px', background: '#f7f7f7' }}>
                
            </div>
            <div>
                <div className="container my-container">
                    <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Your Privacy is Important <span style={{ color: '#ED7117' }}>to Us</span></h1>
                    <div className="line"></div>
                    <div className="row mt-5" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Onlogis respects your privacy</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start text-center" style={{ color: '#797979' }}>
                                We, Onlogis a trading name of Simba Solution Limited (“Onlogis), take the protection of your privacy and your personal data very seriously. We therefore attach great importance to ensuring that your data is secure, and that data processing complies with the applicable data protection regulations, in particular the UK`s Data Protection ACT 2018 (DPA), the General Data Protection Regulation (GDPR). Furthermore, we ensure through technical and organisational measures that your data is optimally protected against unauthorised access by third parties. This data protection notice applies to the online offer of Onlogis and our IOS and Android mobile application.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Personal data</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Personal data is personal information that allows a conclusion to your identity. This includes, for example, information such as your name, address, telephone number or e-mail address, but also information about your surfing behaviour, insofar as this information can be directly or indirectly assigned to you.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Collection of protocol data</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Each time the Onlogis website is accessed, certain data of the calling end device is automatically collected by our web servers and temporarily stored. The following data is collected:
                                <ol>
                                    <li>Technical end device data (device type, operating system version, browser type, screen resolution, colour depth, Javascript on/off, etc.);</li>
                                    <li>The IP address of the calling end device</li>
                                    <li>The date and time of the visit (timestamp)</li>
                                    <li>Internet service provider of the user</li>
                                    <li>Technical request</li>
                                    <li>Session ID</li>
                                    <li>The sub-pages of our website called up by the user</li>
                                    <li>The website from which the respective user visited us (referrer URL)</li>
                                    <li>Web pages that are called up by the user's system via our website.</li>
                                </ol>
                                This data is collected exclusively to ensure the functionality of the website. Furthermore, the data serves to ensure the security of our information technology systems. An evaluation of the data for marketing purposes does not take place in this context.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Provision of the app and creation of log files</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The legal basis for the processing of your personal data in the context of the provision of the app and the creation of log files is our legitimate interest. The temporary storage of your personal data by us is necessary to enable delivery of the App’s functionality. For this purpose, your personal data must be stored for the duration of the session. Data that may be collected when using the App, i.e. IP address, IP location, Geolocation, type and version of the end device used, information on the mobile network used, time zone settings, operating system and platform. The storage of your personal data in log files is done to ensure the functionality of the App. In addition, we use your personal data to optimise the App and to ensure the security of our information technology systems. Your personal data is not processed in any other way.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Your personal data will be deleted as soon as they are no longer required to achieve the purpose for which they were collected. In the case of the collection of your personal data for the provision of the App, this is given as soon as you close the App. In the case of storage of your personal data in log files, these are deleted after 30 days at the latest. If the data is stored beyond this period, your personal data will be anonymised so that it can no longer be assigned. The collection of your personal data for the provision of the App and the storage of your personal data in log files is absolutely necessary for the operation of the App. Consequently, there is no possibility for you to object.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-5" data-aos="zoom-in-right">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>General technical organisational measures</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Onlogis has taken a variety of security measures to protect personal information to an appropriate extent and adequately. All information held by Onlogis is protected by physical, technical, and procedural measures that limit access to the information to specifically authorised persons in accordance with this Privacy Policy.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The Onlogis website is behind a software firewall to prevent access from other networks connected to the Internet. In addition, only employees who need the information to perform a specific job are granted access to personally identifiable information. These employees are trained in security and privacy practices and treat your information confidentially.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Secure transfer of your data</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                In order to best protect the data, we store against accidental or intentional manipulation, loss, destruction or access by unauthorised persons, we use appropriate technical and organisational security measures. The security levels are continuously reviewed in cooperation with security experts and adapted to new security standards.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The exchange of data to and from our website is always encrypted. We offer HTTPS as the transmission protocol for our website, in each case using the current encryption protocols.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                In addition, we offer our users content encryption as part of the contact forms and for applications. The decryption of this data is only possible for us. In addition, there is the option of using alternative communication channels (e.g., the postal service).</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Transmission and disclosure of personal data</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                In the course of our processing of personal data, it may happen that the data is transmitted to other bodies, companies, legally independent organisational units or persons or that it is disclosed to them. Recipients of this data may include, for example, payment institutions in the context of payment transactions, service providers commissioned with IT tasks or providers of services and content that are integrated into a web site. In such cases, we observe the legal requirements and, in particular, conclude appropriate contracts or agreements that serve to protect your data with the recipients of your data.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Economic analyses and market research</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                For business reasons and in order to be able to recognise market trends, wishes of contractual partners and users, we analyse the data we have on business transactions, contracts, inquiries, etc., whereby the group of persons concerned may include contractual partners, interested parties, customers, visitors and users of our website.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The analyses are carried out for the purpose of business evaluations, marketing and market research (e.g., to determine customer groups with different characteristics). In doing so, we may, if available, take into account the profiles of registered users together with their details, e.g., regarding services used. The analyses serve us alone and are not disclosed externally, unless they are anonymous analyses with summarised, i.e., anonymised values. Furthermore, we take the privacy of the users into consideration and process the data for the analysis purposes as pseudonymously as possible and, if feasible, anonymously (e.g., as summarised data).
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Processing of personal data when using the offered services</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Personal data will be collected, processed or used (“used”) in connection with the services offered. This is always done in compliance with the provisions of the DPA and the GDPR. Insofar as we use your personal data for a purpose that requires your consent according to the legal provisions, we will always ask for your express consent.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Administration, financial accounting, office organisation, contact management</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                We process data in the context of administrative tasks as well as organisation of our operations, financial accounting and compliance with legal obligations, such as archiving. In this regard, we process the same data that we process in the course of providing our contractual services. The processing bases are Article 6 (1) (c) GDPR, Article 6 (1) (f) GDPR. Customers, interested parties, business partners and website visitors are affected by the processing. The purpose and our interest in the processing lies in the administration, financial accounting, office organisation, archiving of data, i.e., tasks that serve the maintenance of our business activities, performance of our tasks and provision of our services. The deletion of data with regard to contractual services and contractual communication corresponds to the data mentioned in these processing activities.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                In this context, we disclose or transfer data to the tax authorities, consultants, such as tax advisors or auditors, as well as other fee offices and payment service providers.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                Furthermore, based on our business interests, we store information on suppliers, event organisers and other business partners, e.g., for the purpose of contacting them at a later date. This data, most of which is company-related, is generally stored permanently.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Your Onlogis account</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                You have the option of logging in with your Onlogis account (“Onlogis account”) or registering for it. To register, we ask you to provide your name, and e-mail address. Furthermore, you can optionally provide additional data.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                We process the personal data collected within the scope of the Onlogis account in order to provide you with the aforementioned functionalities.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The legal basis for data processing in connection with the www.onlogis.com account is Art. 6 Para. 1 lit. b GDPR and, if you have given us your consent for the processing of your personal data, Art. 6 Para. 1 lit. a GDPR. Furthermore, the individualisation of our online presence (website and newsletter) is processed on the basis of Art. 6 para. 1 lit. f GDPR, whereby our legitimate interest lies in the improvement of our online presence and services.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                If you wish to delete your Onlogis account completely, you can do so easily in your Onlogis account via the Onlogis App or at www.onlogis.com. You can also delete your data yourself here. You can also correct your data yourself here.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Contacting us</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                If you contact us via a contact form, by telephone, or e-mail, the personal data you provide will be collected and stored. If you contact us by telephone, we may also ask you for your consent to record the conversation for training purposes.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                We use the data collected exclusively for the purpose of answering your enquiry and for other purposes stated when collecting the data. If this is necessary to answer your enquiry.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                We delete the data accruing in this context after the storage is no longer necessary for the purpose of the contact, or – in the case of statutory retention obligations – restrict the processing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Social Media</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                The data you enter on our social media pages, such as comments, videos, pictures, likes, public messages, etc. are published by the social media platform and are not used or processed by us for any other purpose at any time. We only reserve the right to delete content if this should be necessary. Where applicable, we share your content on our site if this is a function of the social media platform and communicate with you via the social media platform. The legal basis is our legitimate interest. The data processing is carried out in the interest of our public relations and communication.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                If you wish to object to certain data processing over which we have an influence, please contact us. We will then examine your objection. If you send us a request on the social media platform, we may also refer you to other secure communication channels that guarantee confidentiality, depending on the response required. You always have the option of sending us confidential enquiries to our address stated in the imprint.
                                </p>
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                As already stated, where the social media platform provider gives us the opportunity, we take care to design our social media pages to be as data protection compliant as possible. With regard to statistics that the provider of the social media platform makes available to us, we can only influence these to a limited extent and cannot switch them off. However, we make sure that no additional optional statistics are made available to us.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Your rights</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                With regard to your personal data, you are generally entitled to the following rights vis-à-vis Onlogis:
                                </p>
                                  <ol>
                                    <li>Right to information</li>
                                    <li>Right to correction or deletion</li>
                                    <li>Right to restriction of processing</li>
                                    <li>Right to object to processing</li>
                                    <li>Right to data portability</li>
                                    <li>Session ID</li>
                                    <li>The sub-pages of our website called up by the user</li>
                                    <li>The website from which the respective user visited us (referrer URL)</li>
                                    <li>Web pages that are called up by the user's system via our website.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Contact Details and Data Controller</h4>
                            </div>
                            <div className="card-body">
                            <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                If you have any questions about the collection, processing, or use of your personal data, or if you wish to request information, corrections, deletions, processing restrictions, objections or the transfer of your data, please contact:
                            </p>
                            <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                            Onlogis a trading name of Simba Solution Limited 71-75 Shelton Street, Covet Garden, London, WC2H 9JQ United Kingdom www.onlogis.com info@onlogis.com Please make sure to provide us with your full contact details (first name, last name and address) when submitting your enquiry. This is the only way we can clearly identify you and answer your enquiry.
                            </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" data-aos="zoom-in-up">
                        <div className="card">
                            <div className="card-header bg-white">
                                <h4 className="fs-6 fw-normal lh-base text-sm-start text-center" style={{ color: '#ED7117' }}>Right to complain</h4>
                            </div>
                            <div className="card-body">
                                <p className="fs-6 lh-base text-sm-start" style={{ color: '#797979' }}>
                                You also have the right to complain to a data protection supervisory authority about the processing of your personal data by Onlogis. The competent supervisory authority in connection with our services is The Information Commissioner’s Office (ICO) in the UK. You have the right to make a complaint at any time to the ICO (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy;
