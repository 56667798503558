import React from 'react';
import Box from './NewBox';

function NewTestimonial() {

    return (
        <div className="customers py-3" style={{ background: '#F7F7F7', height: 'auto' }}>
            <div className="container my-container">
                <div>
                    <h2 className="fs-2 fw-bold lh-base pt-4 text-center" style={{ color: '#797979' }}>Customers <span style={{ color: '#ED7117' }}>Testimonials</span></h2>
                    <div className="line"></div>
                </div>
                <div className="row g-2 my-5">
                    <Box logo="/en/img/alphas.png" name="Haiba Sidahmed" desc="We have been using Onlogis ERP since two years and we are fully satisfied with the level of service, support, features of the ERP and the customization level. We find the team of Onlogis very pragmatic and easy to work with, they understand their product and they are proactive for our needs and concerns" />
                    <Box logo="/en/img/cat.png" name="Ali Djima" desc="For three years we used Onlogis ERP and thanks to its functionalities, we have gained control and clear visibility of the internal and external management of our freight logistics. Indeed it created a significant value for our customers" />
                    <Box logo="/en/img/gato.png" name="Gato Koumako Amegnido" desc="We confirm that the customization and support incorporated with Onlogis software has helped us to successfully seize high opportunities at minimal costs and overcome the challenges of the logistics market in which we operate" />
                </div>
            </div>
        </div>
    )
}

export default NewTestimonial;
