import { createChatBotMessage } from 'react-chatbot-kit';
import Options from '../options/Options';

const botName = "Agent";

const config = {
    botName: botName,
    initialMessages: [createChatBotMessage("Hello can i help You ?"),
    createChatBotMessage(`Please select from these options or ask question`, {
        widget: "options"
    })],
    widgets: [
        {
            widgetName: 'options',
            widgetFunc: props => <Options {...props} />,
        },
    ],
};

export default config;