import React from "react";
import { Link } from "react-router-dom";
import "./Options.css";

const Options = (props) => {
    const options = [
        {
            text: "Discover our ERP",
            handler: props.actionProvider.handleWhatIsOnlogis,
            id: 1,
        },
        {
            text: "Onlogis modules",
            handler: props.actionProvider.modules,
            id: 2,
        },
        {
            text: "Onlogis ERP differentiates",
            handler: props.actionProvider.different,
            id: 3,
        },
        {
            text: "Onlogis purchasing options",
            handler: props.actionProvider.optBuy,
            id: 4,
        },
        {
            text: "Contact Help center",
            handler: props.actionProvider.contactInfo,
            id: 5,
        },
        // {
        //     text: "Pricing plans",
        //     handler: props.actionProvider.pricingPlans,
        //     id: 6,
        // },
    ];

    const buttonsMarkup = options.map((option) => (
        <button key={option.id} onClick={option.handler} className="option-button">
            {option.text === "Pricing Plans" ?
                <Link to="/pricing" style={{ textDecoration: "underline" }}>Pricing Plans</Link>
                : option.text}
        </button>
    ));

    return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;