import React, { useState } from 'react';
import { Button } from '../partials/Button';
import '../css/Pricing.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'
import { API_URL } from '../../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewPricing = () => {
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [country, setCountry] = useState("");

    const navigate = useNavigate();

    const primaryColor = {
        color: '#ED7117'
    }

    const bgInfo = {
        background: '#EBF3FA'
    }

    const sendMessageToScheduleDemo = async (e) => {
        e.preventDefault();
        const data = {
            phone: phone,
            date: date,
            companySize: companySize,
            country: country
        }
        const res = await axios.post(`${API_URL}/schedule-demo-request`, data);
        if (res.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Thank You, Your message is sent successfully",
                showConfirmButton: false,
                timer: 3000
            });
            setPhone("");
            setCountry("");
            setCompanySize("");
            setDate(null);
            navigate('/');
        } else {
            console.log("Error occured !!");
        }
    }

    return (
        <>
            <Helmet>
                <title>Onlogis - Pricing</title>
                <meta name="description" content="Onlogis offers different pricing plans to remain within your company's budget
                including startup, medium and entreprise plan" />
            </Helmet>
            <div className="top mb-sm-5 pt-5" style={{ minHeight: '400px', background: '#f7f7f7', overflow: 'hidden' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex flex-sm-row justify-content-between align-items-center">
                        <p className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" data-aos="fade-down" style={{ color: '#797979' }}>
                            Onlogis offers its customers the best value for money in the market with customized modules that live up to
                            expectations and meet the needs of your business
                        </p>
                        <img srcSet="/en/img/pricings.png" className="img-fluid w-50 img" alt="pricing" data-aos="zoom-in-up" />
                    </div>
                </div>
            </div>
            {/* Pricing plan for mobiles */}
            <div className="container my-container mt-5 hide-from-desktop">
                <div className="row text-start my-5">
                    <div className="col-md-4 mt-5">
                        <div className="plan p-2 ">
                            <h4 className="fs-2 lh-base fw-normal text-center">Start Up Plan</h4>
                            <p className="fs-6 lh-base fw-normal text-center"> User  20$ /month</p>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Minimum Users : </span>
                                <span className="fs-3 fw-normal ms-5 ">3</span>
                            </div>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Set Up Fees : 200$</span>
                            </div>
                            <hr style={{ color: "#ED7117", height: "1px" }} />
                            <h6 className='fs-4 text-center'>List Of Modules</h6>
                            
                            <div className="text-center">
                                <button className='mobile-btn-buy'>
                                    <a className='link' href="https://erp.simba-solution.com" target="_blank" rel="noreferrer">Buy Now</a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div className="plan p-2 ">
                            <h4 className="fs-2 lh-base fw-normal text-center">Medium Entreprise Plan</h4>
                            <p className="fs-6 lh-base fw-normal text-center"> User <br /> 30$ /month</p>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Users : </span>
                                <span className="fs-3 fw-normal ms-5 ">10</span>
                            </div>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Set Up Fees : 500$</span>
                            </div>
                            <hr style={{ color: "#ED7117", height: "1px" }} />
                            <h6 className='fs-4 text-center'>List Of Modules</h6>
                           
                            <div className="text-center">
                                <button className='mobile-btn-buy'>
                                    <Link className='link' to="/en/request-a-quote">Request a Quote</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Princing plan for desktop */}

            <div className="container my-container mt-3  hide-mobile px-0">
                <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Pricing <span style={{ color: '#ED7117' }}>Plans</span></h1>
                <div className="line"></div>
                <table className="table table-bordered border shadow p-2 mb-5 bg-body rounded mt-5" >
                    <thead>
                        <tr>
                            <td className='p-0'>
                                <p>&nbsp;</p>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title">
                                    Start up Plan
                                </h5>
                                <span className='price'>20$ <br /> User / Month</span>
                            </td>
                            <td className="text-center fw-bold p-0" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '28px' }}>
                                    Medium Plan
                                </h5>
                                <span className='price'>20$ <br /> User / Month</span>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '30px' }}>
                                    Entreprise Plan
                                </h5>
                                <span className='price'>Request a Quote</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Minimum Users</td>
                            <td className="text-center fs-3" style={primaryColor} >
                                1
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                1
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                Unlimited
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Modules</td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Sales</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Inventory</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        {/* <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Accounting</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr> */}
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Purchase</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Contacts</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Payment</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>HR Management</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Accounting</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Freight</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Vessel</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                            <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Transport</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                            <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Custom</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Access Rights</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50"><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Multi Companies</h2></td>
                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                        </tr>
                        
                        
                        <tr>
                            <td className="p-2 fs-3 w-50 " style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Set Up Fees</h2></td>
                            <td className="text-center fs-3" style={primaryColor}>
                                200$
                            </td>
                            <td className="text-center fs-3" style={primaryColor}>
                                500$
                            </td>
                            <td className="text-center fs-4" style={primaryColor} >
                                {/* <i className="fas fa-chevron-down"></i> */}
                                To be Estimated
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50 " style={bgInfo}></td>
                            <td className="text-center fs-3">
                                <Button buttonStyle="btn-my-primary" style={{ color: '#fff' }} type="submit">
                                    <a href="https://erp.simba-solution.com/shop/start-up-plan-49?category=1#attr=" target="_blank" rel="noreferrer">Buy Now</a>
                                </Button>
                            </td>
                            <td className="text-center fs-3">
                                <Button buttonStyle="btn-my-primary" style={{ color: '#fff' }} type="submit">
                                    <a href="https://erp.simba-solution.com/shop/medium-plan-50?category=1#attr=" target="_blank" rel="noreferrer">Buy Now</a>
                                </Button>
                            </td>
                            <td className="text-center fs-3">
                                <Button buttonStyle="btn-my-primary" type="submit">
                                    <Link className='link' to="/en/request-a-quote">Request Quote</Link>
                                </Button>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="container my-container my-5 text-center" style={{ overflow: 'hidden' }}>
                <div className="pt-5">
                    <h4 className="fs-2">Ready to get started?</h4>
                    <h2 className="fs-1 mt-3 mb-4">
                        Schedule a demo, and try Onlogis for 7 days free trial
                    </h2>
            
                    <Button buttonStyle="btn-my-primary" style={{ color: '#fff' }} type="submit">
                        <Link className='link' to="/en/request-a-demo">Free Trial</Link>
                    </Button>
                </div>
                <img srcSet="/en/img/technical-support1.png" className="img-fluid mt-5" alt="Schedule a demo" />
            </div>
        </>
    )
}

export default NewPricing;
