import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/auth.css';

function SignIn() {
    return (
        <>
            <Helmet>
                <title>Onlogis - Sign In</title>
                <meta name="description" content="Sign-In to our services by entering your email and password. Get the best experience by signing up to our services and enjoy them while growing your business to the next level." />
            </Helmet>
            <div className="sign-in py-5 " style={{ minHeight: '400px', background: '#f7f7f7' }}>
                <div className="container my-container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-5 p-5 full-w">
                            <h3 className="fs-4 lh-base text-center  " style={{ color: '#ED7117' }}>Sign In</h3>
                            <form>
                                <div className="form-group mb-3 position-relative">
                                    <input type="email" name="email" placeholder="Enter Your Email" className="input" />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <input type="password" name="password" placeholder="Enter Your password" className="input" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn-auth text-white fs-5">
                                        Sign In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn;
