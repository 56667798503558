import React, {useState } from 'react';
import { Helmet } from 'react-helmet';
import '../../css/auth.css';
import Swal from 'sweetalert2';
import { countries, companies_size } from '../../../countries';
import axios from 'axios';
import { API_URL } from '../../../App';
import { useNavigate } from 'react-router-dom';

function SignUp() {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [country, setCountry] = useState("");
    const navigate = useNavigate();

    const register = async (e) => {
        e.preventDefault();

        const data = {
            fullName: fullName,
            email: email,
            phone: phone,
            country: country,
            companySize: companySize,
        }

        const res = await axios.post(`${API_URL}/clients-signup`, data);
        if (res.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Thank You, Your message is sent successfully",
                showConfirmButton: false,
                timer: 3000
            });
            setEmail("");
            setPhone("");
            setCountry("");
            setCompanySize("");
            navigate('/');
        } else {
            console.log("Error occured !!");
        }
    }

    return (
        <>
            <Helmet>
                <title>Onlogis - Sign up</title>
                <meta name="description" content="Sign up - Give us a call or send us an email we will get back to you as soon as possible. Mention your name, email, phone, country, and company size." />
            </Helmet>
            <div className="contact-us" style={{ background: '#f7f7f7', overflow: 'hidden' }}>
                <div className="container my-container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-5">
                            <h3 className="fs-3 lh-base text-center pt-3" style={{ color: '#ED7117' }}>Sign Up</h3>
                            <p className="lead lh-base fs-6 mt-3" style={{ color: '#888' }}>Give us a call or send us an email and we will get back to you as soon possible</p>
                            <form onSubmit={register}>
                                <div className="form-group mb-3 position-relative">
                                    <input type="text" name="fullName" placeholder="Your Full Name" className="input" required
                                        value={fullName} onChange={e => setFullName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <input type="email" name="email" placeholder="Your Email" className="input" required
                                        value={email} onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                        value={phone} onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                        <option selected>Choose your country</option>
                                        {
                                            countries.map((country, index) => (
                                                <option value={country} key={index}>{country}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                        <option selected>Choose your company size</option>
                                        {
                                            companies_size.map((size, index) => (
                                                <option value={size} key={index}>{size}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-group mb-5">
                                    <button type="submit" className="btn-auth text-white fs-5">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp;
