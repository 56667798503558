import React from 'react';

function NewBox({ logo, desc, name, post = 'Managing Director' }) {
    return (
        <div className="col-md-4">
            <div className="card p-3 text-center px-4 mb-3 mb-sm-0" style={{ minHeight: '430px' }}>
                <div className="user-image">
                    <img src={logo} alt="Logo" className="img-fluid mb-2" />
                </div>
                <div className="user-content">
                    <h5 className="mb-1">{name}</h5>
                    <span className="mb-2" style={{ color: '#ED7117' }}>{post}</span>
                    <p className="fs-6 fw-normal lh-base">
                        {desc}
                    </p>
                </div>
                <div className="ratings">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                </div>
            </div>
        </div>
    )
}

export default NewBox;
