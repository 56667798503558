class ActionProvider {
    constructor(createChatbotMessage, setStateFunc, createClientMessage) {
        this.createChatbotMessage = createChatbotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
    }

    welcomeMsg = () => {
        const message = this.createChatbotMessage(`Hi and welcome, thank you for interesting to our onlogis ERP`)
        const message2 = this.createChatbotMessage('Choose from these option above or ask question')
        this.addMessageToState(message, message2);
    }

    msgThanks = () => {
        const message = this.createChatbotMessage(`Thank you for your query; 
         send us an email at info@onlogis.com or send us a message on whats app at +442037692827 `)
        this.addMessageToState(message);
    }

    myAlert = () => {
        const message = this.createChatbotMessage("Please I did not understand !")
        this.addMessageToState(message);
    }

    handleWhatIsOnlogis = () => {
        const message = this.createChatbotMessage("Onlogis is acloud based ERP for Freight Forwarders and Logistic Service Providers including business management and analytics tools. It is simple, flexible, Scalable and Extensible software which combines a wide range of functions needed by your company into one unified system.")
        this.addMessageToState(message);
    }

    modules = () => {
        const message = this.createChatbotMessage(`
        Transportation and warehouses management,
        Customs Clearance,
        Financial Management,
        Partner and Supplier Management,
        Purchase order Management,
        Orders Approvals and control,
        Sales and offers management,
        HR management,
        CRM,
        Dashboard,
        Reporting and Analysis Module
        `);
        this.addMessageToState(message);
    }

    different = () => {
        const message = this.createChatbotMessage(` Real-time visibility to key business indicators,
        Customized billing system,
        Flexible pricing system Accessible everywhere and anywhere,
        Country Specific Customs Regulations,
        Multi-site solution`)
        this.addMessageToState(message);
    }

    optBuy = () => {
        const message = this.createChatbotMessage(`Onlogis is a cloud service thus you can use it depending on your demands. You can subscribe to the services by paying monthly cost per user.Alternatively you can buy the license software and deploy it on your own IT infrastructure located at in your office or at the service provider.`)
        this.addMessageToState(message);
    }

    contactInfo = () => {
        const message1 = this.createChatbotMessage(`Email 📧 : info@onlogis.com`)
        const message2 = this.createChatbotMessage(`Phone number : 📱 +442037692827`)
        this.addMessageToState(message1, message2);
    }

    getUserEmailQuestion = () => {
        const message = this.createChatbotMessage("Tell us your email ")
        this.addMessageToState(message);
    }

    getUserPhoneNumberQuestion = () => {
        const message = this.createChatbotMessage("Tell us your phone number ")
        this.addMessageToState(message);
    }


    pricing = () => {
        const message1 = this.createChatbotMessage("Start up plan : 35$ user / month")
        const message2 = this.createChatbotMessage("Medium  plan : 30$ user / month")
        const message3 = this.createChatbotMessage("Entreprise plan : Please contact us")
        this.addMessageToState(message1, message2, message3);
    }

    addMessageToState = (message, message2 = "", message3 = "") => {
        this.setState((prevState) => ({
            ...prevState,
            messages: [...prevState.messages, message, message2, message3],
        }));
    };
}

export default ActionProvider;