import React from 'react';
import Carousel from 'react-elastic-carousel';
import '../css/Clients.css';

function Clients() {
    const breakPoints = [
        {
            width: 500,
            itemsToShow: 1
        },
        {
            width: 768,
            itemsToShow: 2
        },
        {
            width: 1200,
            itemsToShow: 2
        },
        {
            width: 1500,
            itemsToShow: 2
        }
    ];

    return (
        <div className="client pt-4 p-sm-5 p-lg-4 text-center text-sm-start w-100" style={{ background: '#fff', minHeight: '400px' }}>
            <div className="container my-container">
                <div className="row text-center mt-3">
                    <h2 className="fs-2 fw-bold lh-base pt-4" style={{ color: '#797979' }}>Satisfied <span style={{ color: '#ED7117' }}>Client Base</span></h2>
                    <div className="line"></div>
                </div>
                <div>
                    {/* <Carousel className="my-5" breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={2000}>
                        <img srcSet="img/cat.png" alt="" />
                        <img srcSet="img/alphas.png" className="w-25" alt="" />
                        <img srcSet="img/gato.png" alt="" />
                    </Carousel> */}
                    <Carousel className="my-5" breakPoints={breakPoints} enableAutoPlay autoPlaySpeed={1500}>
                        <img srcSet="/en/img/cat.png" alt="" />
                        <img srcSet="/en/img/alphas.png" className="w-25" alt="" />
                        <img srcSet="/en/img/gato.png" alt="" />
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Clients;
