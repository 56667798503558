export const DropdownItems = [
    {
        ItemName: 'Installation',
        url: '/en/user-guide',
        cName: 'nav-link'
    },
    {
        ItemName: 'Tech Support',
        url: '/en/technical-support',
        cName: 'nav-link'
    },
    {
        ItemName: 'Video Training',
        url: '/en/demo-training',
        cName: 'nav-link'
    }
]